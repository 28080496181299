import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop() {
    const {pathname} = useLocation();

    const pageAnimation = () => {
        setTimeout(() => {
            document.querySelector('.main-page-wrapper').scrollTo(0, 0);
        }, 500)
    }

    if (pathname === '/') {
        pageAnimation()
    }
    useEffect(() => {
        pageAnimation()
    }, [pathname]);

    return null;
}
