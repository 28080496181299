import React, {useState, useEffect} from 'react'
import styled from 'styled-components'

const placeHolder =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='

const Image = styled.img`
  @keyframes loaded {
    0% {
      opacity: 0;
      margin-top: 50px;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }
  &.loaded:not(.has-error) {
    animation: loaded 300ms ease-in-out;
  }
  &.has-error {
    content: url(${placeHolder});
  }
`

export const LazyImage = ({src, alt, bgImg, className}) => {
    const [imageSrc, setImageSrc] = useState(placeHolder)
    const [imageRef, setImageRef] = useState()


    const onError = event => {
        event.target.classList.add('has-error')
    }


    useEffect(() => {
        let observer
        let didCancel = false

        if (imageRef && imageSrc !== src) {
            if (IntersectionObserver) {
                observer = new IntersectionObserver(
                    entries => {
                        entries.forEach(entry => {
                            if (
                                !didCancel &&
                                (entry.intersectionRatio > 0 || entry.isIntersecting)
                            ) {
                                setImageSrc(src)
                                observer.unobserve(imageRef)
                                imageRef.classList.add('loaded')
                            }
                        })
                    },
                    {
                        threshold: 0,
                        root: document.querySelector(".main-page-wrapper"),
                        rootMargin: '200px'
                    }
                )
                observer.observe(imageRef)
            } else {
                setImageSrc(src)
            }
        }
        return () => {
            didCancel = true
            if (observer && observer.unobserve) {
                observer.unobserve(imageRef)
            }
        }
    }, [src, imageSrc, imageRef])
    return (
        bgImg ?
            <div className={className}
                 onError={onError}
                 ref={setImageRef}
                 style={{backgroundImage: `url(${imageSrc})`}}
            /> :
            <Image
                className={className}
                ref={setImageRef}
                src={imageSrc}
                alt={alt}
                onError={onError}
            />
    )
}
