 import React, {useRef} from 'react'
import Loader from "../../shared/loader/Loader";
import {Link} from "react-router-dom";
import {videoHoverNumber} from "../../../actions";
import {useDispatch, useSelector} from "react-redux";
import {useWindowSize} from "../../hooks/windowSize";

const Categories = (props) => {

    const categoryRef = useRef([]);
    const dispatch = useDispatch();
    const {aboutData} = useSelector(state => state.about)
    const {videoNumber} = useSelector(state => state.cursorHover)
    const lemonCursor = document.querySelector('.lemon-cursor');
    const size = useWindowSize()

    const mouseLeave = () => {
        if (size > 767) {
            lemonCursor.classList.add('closing-video-circle')
            setTimeout(() => {
                dispatch(videoHoverNumber(null))
                lemonCursor.classList.remove('closing-video-circle')
            }, 400)
        }
    }
    const onMouseEnter = (category) => {
        if (size > 767) {
            dispatch(videoHoverNumber(category))
        }
    }

    const correctNumber = (categoryId) => categoryId === videoNumber;
    return (
        <section ref={props.sectionRef} className="categories-section loader-parent">
            <div className="text-content">
                <h2><span>Czym się</span> zajmujemy</h2>
                <div className="description" dangerouslySetInnerHTML={{ __html: aboutData.what_we_do }} />
            </div>
            {props.isLoading === true ?
                <Loader/> :
                <div className="categories-wrapper" onMouseLeave={mouseLeave}>
                    {props.categoriesData.map((category, number) => {
                        return (
                            <Link
                                ref={categoryRef}
                                onMouseEnter={() => onMouseEnter(category.id)}
                                to={`/${category.name_url}`}
                                className="category"
                                key={category.id}
                                onClick={() => {
                                    dispatch(videoHoverNumber(null))
                                }}
                            >
                                <div className="category-info">
                                    <div className="number">0{number + 1}</div>
                                    <div className="name">{category.category_name}</div>
                                </div>
                                <div className="video-box">
                                    {videoNumber && correctNumber(category.id) &&
                                    <video preload="none" loop muted key={videoNumber} autoPlay>
                                        <source src={`https://app.lemonsniff.pl/assets/img/uploads/categories/hover-${videoNumber}.mp4`} type="video/mp4"/>
                                    </video>
                                    }
                                </div>
                            </Link>
                        )
                    })}
                </div>
            }

        </section>
    )
}

export default Categories;
