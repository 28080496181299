import {useEffect, useState} from "react";

export function useWindowSize() {
    const isClient = typeof window === 'object';

    const getSize = () => {
        return isClient ? window.innerWidth : undefined;
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }
        setWindowSize(getSize());
    }, [isClient, getSize]);

    return windowSize;
}
