import React, {useEffect, useRef, useState} from "react";
import myAxios from "../../../apis/myAxios";
import {useForm} from "react-hook-form";
import {postContactForm} from "../../../actions";
import MetaDecorator from "../../shared/MetaDecorator";

const Brief = (props) => {

    const [ready, setReady] = useState(false);
    const [fieldData, setFieldData] = useState([])
    const [required, setRequired] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [errorData, setError] = useState(true)
    const {register, handleSubmit, errors} = useForm()

    useEffect(() => {
        myAxios.get(`/brief`)
            .then(response => {
                setFieldData(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                setError(error.response)
            })
        setReady(false)
    }, [isLoading])

    const onSubmit = (data) => {
        const getFormData = (object) => {
            const formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        }
        myAxios.post('/brief', getFormData(data))
            .then(response => {

            })
            .catch(error => {
                setTimeout(() => {

                }, 1250)
            })
    }

    return (
        <section className="brief-section">
            <MetaDecorator title='Brief - Lemon Sniff'/>
            <div className="container">
                <div className="brief-wrapper">
                    <h1>Wypełnij <span>brief</span></h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fieldData.map(field => {
                            return (
                                <div className="form-group textarea" key={field.id}>
                                    <label className="control-label"
                                           htmlFor={field.field_name}>{field.label}</label>
                                    {errors[field.field_name] && <div className="validation-alert">Pole jest wymagane</div>}
                                    <textarea placeholder={field.placeholder} className="form-control" rows="7"
                                              name={field.field_name}
                                              ref={register({required: field.required == 1})}/>
                                    <i className="icon-write"></i>
                                </div>
                            )
                        })}
                        <button type="submit" className="submit-btn">Wyślij</button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Brief;
