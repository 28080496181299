const initialState = {
    isLoadingRealizations: false,
    isError: false,
    data: [],
}
export default (state= initialState, action) => {
    switch (action.type) {
        case 'GET_DATA_REQUESTED':
            return { ...state, isLoadingRealizations: true };
        case 'GET_DATA_DONE':
            return { ...state, isLoadingRealizations: false, data: action.payload };
        case 'GET_DATA_FAILED':
            return { ...state, isLoadingRealizations: false, isError: true}
        default:
            return state;
    }
};
