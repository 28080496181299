import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAboutData, getMainRealizations} from "../../actions";
import Loader from "./loader/Loader";
import {Link} from "react-router-dom";

const ContactData = ({inModal, closeModalFunction}) => {

    const dispatch = useDispatch();
    const {aboutData, isLoading} = useSelector(state => state.about)

    useEffect(() => {
        if (aboutData.length === 0) {
            dispatch(getAboutData());
        }
    }, [])

    const closeModalAfterBriefClick = () => {
        setTimeout(() => {
            closeModalFunction()
        }, 400)
    }

    return (
        <div className="address">
            {isLoading ?
                <Loader/> :
                <>
                    <div>
                        <div className="title">Adres</div>
                        <div className="value">{aboutData.street}<br/>
                            {aboutData.zip_code} {aboutData.city}
                        </div>
                        <a target="_blank" href={aboutData.map_link} type="button" className="see-on-map">
                            <div className="icon">
                                <i className="icon-gps"></i>
                            </div>
                            Zobacz na mapie
                        </a>
                    </div>
                    <div>
                        <div className="title">Telefon</div>
                        <div className="value">
                            <a href={`tel:${aboutData.phone}`}>{aboutData.phone}</a> <br/>
                            <a href={`tel:${aboutData.phone2}`}>{aboutData.phone2}</a>
                        </div>
                    </div>
                    <div>
                        <div className="title">E-mail</div>
                        <div className="value">
                            <a href={`mailto:${aboutData.email}`}>{aboutData.email}</a> <br/>
                        </div>
                    </div>
                    {inModal &&
                    <div>
                        <div className="title">Brief</div>
                        <div className="value">
                            <Link onClick={closeModalAfterBriefClick} to="/brief">Wypełnij formularz</Link> <br/>
                        </div>
                    </div>
                    }
                </>
            }
        </div>
    )
}


export default ContactData;
