import React from "react";
import {useDispatch} from "react-redux";
import {closeModal, contactFormSendAgain, resetState} from "../../../actions";
import {Link} from "react-router-dom";

const SendMsg = ({isSent}) => {
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(closeModal())
        dispatch(contactFormSendAgain());
    };

    return (
        isSent ?
            <div className="info-msg success">
                <div className="icon-column">
                    <img src="/lemon-sniff-shape.svg" alt=""/>
                    <i className="icon-tick"/>
                </div>
                <div className="content-column">
                    <h1>Udało się!</h1>
                    <div>Twoja wiadomość <br/> została wysłana</div>
                    <button
                        type="button"
                        onClick={onClick}
                        className="underline-button"
                    >
                        Powrót
                    </button>
                </div>
            </div> :
            <div className="info-msg">
                <h2>Coś poszło <span className="font-weight-light">nie tak</span></h2>
                <button
                    type="button"
                    onClick={() => dispatch(resetState())}
                    className="underline-button"
                >
                    Wyślij ponownie
                </button>
            </div>

    )
}
export default SendMsg;
