const initialState = {
    isLoading: true,
    isError: false,
    data: [],
}
export default (state= initialState, action) => {
    switch (action.type) {
        case 'GET_CLIENTS_DATA_REQUESTED':
            return { ...state, isLoading: true };
        case 'GET_CLIENTS_DATA_DONE':
            return { ...state, isLoading: false, data: action.payload };
        case 'GET_CLIENTS_DATA_FAILED':
            return { ...state, isLoading: false, isError: true}
        default:
            return state;
    }
};
