const initialState = {
    isLoading: null,
    isError: false,
    isSent: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'CONTACT_FORM_REQUESTED':
            return {...state, isLoading: true};
        case 'CONTACT_FORM_DONE':
            return {...state, isLoading: false, isSent: true};
        case 'CONTACT_FORM_FAILED':
            return {isLoading: false, isError: true, isSent: false};
        case 'CONTACT_FORM_SEND_AGAIN':
            return {...state, isLoading: null, isSent: null};
        case 'CONTACT_RESET_STATE':
            return initialState;
        default:
            return state;
    }
};
