const initialState = {
    isLoadingAbout: true,
    isErrorAbout: false,
    aboutData: [],
}
export default (state= initialState, action) => {
    switch (action.type) {
        case 'GET_ABOUT_DATA_REQUESTED':
            return { ...state, isLoadingAbout: true };
        case 'GET_ABOUT_DATA_DONE':
            return { ...state, isLoadingAbout: false, aboutData: action.payload };
        case 'GET_ABOUT_DATA_FAILED':
            return { ...state, isLoadingAbout: false, isErrorAbout: true}
        default:
            return state;
    }
};
