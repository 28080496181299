import React, {useEffect, useState} from "react";

const Loader = () => {

    const [newAnimation, setNewAnimation] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setNewAnimation(true)
        }, 1500)
    })
    return (
        <div className={`intro-loader ${newAnimation ? 'new-animation' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.13 40.84">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="bg">
                        <path
                            d="M51.24,17.12a7.4,7.4,0,0,0-2.87-2.92,17.84,17.84,0,0,1-5.44-5.56,20.09,20.09,0,0,0-2.26-2.7,20.87,20.87,0,0,0-28.88-.27A20.83,20.83,0,0,0,8.54,9.74a8.82,8.82,0,0,1-4,3.73A7.33,7.33,0,0,0,4.38,27a8.81,8.81,0,0,1,4,3.8,20.92,20.92,0,0,0,3.18,4.14,20.52,20.52,0,0,0,14.6,6,20.45,20.45,0,0,0,14.28-5.67,20.7,20.7,0,0,0,2.31-2.64,18.08,18.08,0,0,1,5.57-5.48,7.45,7.45,0,0,0,2.89-2.82h0A7.22,7.22,0,0,0,51.24,17.12Zm-3.42,5.16a3.61,3.61,0,0,1-1.39,1.38,21.76,21.76,0,0,0-6.85,6.59A16.64,16.64,0,0,1,37.7,32.4a16.94,16.94,0,0,1-23.44-.23,16.47,16.47,0,0,1-2.59-3.36,12.78,12.78,0,0,0-5.73-5.4,3.52,3.52,0,0,1-1.67-1.55,3.39,3.39,0,0,1,0-3.31A3.57,3.57,0,0,1,6,17a12.75,12.75,0,0,0,5.84-5.28A16.86,16.86,0,0,1,37.93,8.66a18.24,18.24,0,0,1,1.84,2.19,21.1,21.1,0,0,0,6.71,6.72A3.56,3.56,0,0,1,47.86,19,3.38,3.38,0,0,1,47.82,22.28Z"/>
                        <polygon points="14.41 15 14.41 25.84 23.8 20.42 14.41 15"/>
                        <polygon points="24.93 33.22 24.93 22.38 15.55 27.8 24.93 33.22"/>
                        <polygon points="27.2 33.22 36.59 27.8 27.2 22.38 27.2 33.22"/>
                        <polygon points="37.72 25.84 37.72 15 28.34 20.42 37.72 25.84"/>
                        <polygon points="27.2 7.62 27.2 18.45 36.59 13.03 27.2 7.62"/>
                        <polygon points="24.93 18.45 24.93 7.62 15.55 13.03 24.93 18.45"/>
                        <polygon points="14.41 15 14.41 25.84 23.8 20.42 14.41 15"/>
                        <polygon points="24.93 33.22 24.93 22.38 15.55 27.8 24.93 33.22"/>
                        <polygon points="27.2 33.22 36.59 27.8 27.2 22.38 27.2 33.22"/>
                        <polygon points="37.72 25.84 37.72 15 28.34 20.42 37.72 25.84"/>
                        <polygon points="27.2 7.62 27.2 18.45 36.59 13.03 27.2 7.62"/>
                        <polygon points="24.93 18.45 24.93 7.62 15.55 13.03 24.93 18.45"/>
                    </g>
                </g>
            </svg>
        </div>
    )
}


export default Loader;
