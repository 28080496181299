const initialState = {
    videoNumber: null,
    defaultHovered: false,
    extraTextHovered: false,
    hideCursor: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'VIDEO_HOVER_NUMBER' :
            return {...state, videoNumber: action.payload};
        case 'DEFAULT_ITEM_HOVER' :
            return {...state, defaultHovered: true};
        case 'DEFAULT_ITEM_LEAVE' :
            return {...state, defaultHovered: false};
        case 'TEXT_ITEM_HOVER' :
            return {...state, extraTextHovered: true};
        case 'TEXT_ITEM_LEAVE' :
            return {...state, extraTextHovered: false};
        case 'HIDE_CURSOR' :
            return {...state, hideCursor: !state.hideCursor};
        case 'DEFAULT_CURSOR' :
            return initialState;
        default :
            return state;
    }
};
