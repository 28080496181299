import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import ContactButton from "../contact/ContactButton";
import Loader from "../loader/Loader";

const Ceo = ({seoPage}) => {

    const {isLoadingAbout, isErrorAbout} = useSelector(state => state.about)
    const SeoBtn = ({seoProps}) => {
        if (seoProps.is_contact_btn === '0') {
            return <Link className="underline-button" to={seoProps.link}>{seoProps.button_text}</Link>
        } else {
            return (
                <ContactButton
                    buttonName={seoProps.button_text}
                    underlineBtn
                />
            )
        }
    }
    if (isLoadingAbout) {
        return <Loader />
    }
    return (
        <section className="seo-section">
            {seoPage.map(seo => {
                return (
                    <div key={seo.id}>
                        <div className="title-col">
                            <h2>
                                {seo.header_text}
                            </h2>
                            <SeoBtn seoProps={seo}/>
                        </div>
                        <div className="text-col" dangerouslySetInnerHTML={{ __html: seo.description }} />
                    </div>
                )
            })}
        </section>
    )
}
export default Ceo;
