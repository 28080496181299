import React, {useEffect, useRef, useState} from "react";
import myAxios from "../../../apis/myAxios";
import Loader from "../../shared/loader/Loader";
import {Link} from "react-router-dom";
import NotFound from "../404";
import Reveal from "react-reveal/Reveal";
import {LazyImage} from "../../shared/lazyImg/LazyImage";
import {textItemHover, textItemLeave} from "../../../actions";
import {useDispatch} from "react-redux";
import MetaDecorator from "../../shared/MetaDecorator";

const Category = (props) => {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState({})
    const categoryPrevRef = useRef();
    const categoryNextRef = useRef();
    const dispatch = useDispatch()
    let singleRealization;
    let seoSection;

    useEffect(() => {
        const getFormData = (categoryUrl) => {
            const formData = new FormData();
            formData.append('category_url', categoryUrl)
            return formData;
        }
        myAxios.post(`/category`, getFormData(props.match.params.categoryName))
            .then(response => {
                setData(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                setError(error.response)
            })
    }, [props.match.params.categoryName])

    if (error.status === 404) {
        return <NotFound/>
    }
    if (!isLoading) {
        singleRealization = data.realizations.map(realization => {
            return (
                <div key={realization.id}>
                    <MetaDecorator title={`${realization.category_name} - Lemon Sniff`}/>
                    <Link
                        className="realization"
                        to={`/${props.match.params.categoryName}/${realization.name_url}`}
                        key={realization.id}
                        onMouseEnter={() => {
                            dispatch(textItemHover())
                        }}
                        onMouseLeave={() => {
                            dispatch(textItemLeave())
                        }}
                    >
                        <div className="info-col">
                            <h4 className="title">{realization.title}</h4>
                            <div className="description">{realization.category_name}</div>
                        </div>
                        <div className="photo-col">
                            <LazyImage bgImg className="photo"
                                       src={`https://app.lemonsniff.pl/assets/img/uploads/${realization.id}.jpg`}/>
                        </div>
                    </Link>
                </div>
            )
        })
    }

    return (
        <div className="container">
            {isLoading ? <Loader/> :
                <>
                    <section className="single-category-wrapper">
                        <div className="single-category-intro">
                            <Reveal effect="fadeInUp" effectOut="fadeOutLeft">
                                <div className="category-title">Kategoria
                                    <div>{data.category_name}</div>
                                </div>
                                <div className="description" dangerouslySetInnerHTML={{ __html: data.description }} />
                            </Reveal>
                        </div>
                        <div className="realizations-wrapper">
                            {singleRealization}
                        </div>
                    </section>
                    {data.seo_description ?
                        <section className={'seo-section'}>
                            <div key={data.id}>
                                <div className="title-col">
                                    <h2>
                                        {data.seo_title}
                                    </h2>
                                </div>
                                <div className="text-col" dangerouslySetInnerHTML={{ __html: data.seo_description }} />
                            </div>
                        </section>
                        : '' }
                    <div className="more-category-section">
                        <h2>Więcej <span>kategorii</span></h2>
                        <div className="flex-row">
                            <Link ref={categoryPrevRef} to={`/${data.prev.name_url}`}
                                  className="prev">
                                <div className="button">
                                    <i className="icon-arrow left"></i>
                                    <span>Poprzednia</span>
                                </div>
                                <div className="category-name" data-text={data.prev.category_name}>
                                    {data.prev.category_name}
                                </div>
                            </Link>
                            <Link ref={categoryNextRef} to={`/${data.next.name_url}`}
                                  className="next">
                                <div className="button">
                                    <span>Następna</span>
                                    <i className="icon-arrow right"></i>
                                </div>
                                <div className="category-name" data-text={data.next.category_name}>
                                    {data.next.category_name}
                                </div>
                            </Link>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Category;
