const initialState = {
    show: false,
    closing: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'OPEN_MODAL' :
            return {...state, show: true};
        case 'CLOSE_MODAL' :
            return {...state, show: false};
        case 'CLOSING_MODAL' :
            return {...state, closing: !state.closing};
        default :
            return state;
    }
};
