import React from "react";
import {useForm} from "react-hook-form";
import SendMsg from "./SendMsg";
import Loader from "../loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import {postContactForm} from "../../../actions";
import Header from "../Header";

const ContactForm = () => {
    const {register, handleSubmit, errors} = useForm()
    const dispatch = useDispatch();
    const {isLoading, isSent} = useSelector(state => state.contactForm)

    const onSubmit = (data) => {
        dispatch(postContactForm(data))
    }

    return (
        isLoading === false ?
            <SendMsg isSent = {isSent}/> :
            <div className="form-col">
                {isLoading &&
                <Loader/>
                }
                <div className="form-wrapper">
                    <h1>Napisz <span>do nas</span></h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="half-width-inputs">
                            <div className="form-group">
                                <label className="control-label" htmlFor="name">Imię i nazwisko</label>
                                {errors.name && <div className="validation-alert">Pole jest wymagane</div>}
                                <input placeholder="Wpisz imię" className="form-control" type="text" name="name"
                                       ref={register({required: true})}/>
                                <i className="icon-profile"></i>

                            </div>
                            <div className="form-group">
                                <label className="control-label" htmlFor="email">E-mail</label>
                                {errors.email && <div className="validation-alert">Pole jest wymagane</div>}
                                <input placeholder="Wpisz adres email" className="form-control" type="email" name="email"
                                       ref={register({required: true})}/>
                                <i className="icon-email"></i>
                            </div>
                        </div>
                        <div className="form-group textarea">
                            <label className="control-label" htmlFor="message">Opowiedz nam o swoim
                                projekcie</label>
                            {errors.message && <div className="validation-alert">Pole jest wymagane</div>}
                            <textarea placeholder="Kilka słów o projekcie" className="form-control" rows="7" name="message"
                                      ref={register({required: true})}/>
                            <i className="icon-write"></i>
                        </div>
                        <button type="submit" className="submit-btn">Wyślij</button>
                    </form>
                </div>
            </div>
    )


}

export default ContactForm
