import {openModal} from "../../../actions";
import React from "react";
import {useDispatch} from "react-redux";

const ContactButton = (props) => {

    const dispatch = useDispatch();
    const openModalFunction = () => {
        dispatch(openModal());
    };

    return (
        <button type="button" className={`${props.inHeader ? 'translating-button with-bg' : 'contact-button'} ${props.underlineBtn ? 'underline-button' : ''}`} onClick={openModalFunction}>
            {props.inHeader ?
                <em>
                    <span data-text={`${props.buttonName}`}>{props.buttonName}</span>
                </em> :
                props.buttonName
            }
        </button>
    )
}
export default ContactButton;


