import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSingleRealization, textItemHover, textItemLeave} from '../../../actions'
import Loader from "../../shared/loader/Loader";
import {Link} from "react-router-dom";
import {LazyImage} from "../../shared/lazyImg/LazyImage";
import NotFound from "../404";
import myAxios from "../../../apis/myAxios";
import MetaDecorator from "../../shared/MetaDecorator";

const Realization = (props) => {

    const prevRef = useRef();
    const nextRef = useRef();
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);
    let realizations;
    let photos;
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [errorData, setError] = useState(true)

    useEffect(() => {
        myAxios.get(`/realizations/single/${props.match.params.name}`)
            .then(response => {
                setData(response.data)
                setIsLoading(false)
            })
            .catch(error => {
                setError(error.response)
            })
        setReady(false)
    }, [props.match.params.name])


    if (errorData.status === 500) {
        return <NotFound/>
    }
    if (!isLoading) {
        realizations = data.extraRealizations.map(extraRealization => {
            return (
                <Link key={extraRealization.id}
                      to={`/${extraRealization.category_url}/${extraRealization.name_url}`}
                      onMouseEnter={() => {
                          dispatch(textItemHover())
                      }}
                      onMouseLeave={() => {
                          dispatch(textItemLeave())
                      }}>
                    <LazyImage bgImg className="photo"
                               src={`https://app.lemonsniff.pl/assets/img/uploads/${extraRealization.id}.jpg`}/>
                    <div className="title">
                        {extraRealization.title}
                    </div>
                    <div className="desc">
                        {extraRealization.category_name}
                    </div>
                </Link>
            )
        })

        if(data.photos.length > 0) {
            photos = data.photos.map(singlePhoto => {
                return (
                    <div className={'lemon-photo-wrapper'}>
                        <LazyImage alt={data.title}
                                   src={`https://app.lemonsniff.pl/assets/img/uploads/realizations/${singlePhoto.file_name}`}/>
                    </div>
                )
            })
        }
    }

    return (
        <div className="container">
            <MetaDecorator title={`${data.title} - Lemon Sniff`}/>
            {isLoading ?
                <Loader/> :
                <>
                    <section className="single-realization-section">
                        <div className="category-name">{data.category_name}</div>
                        <h2>{data.title}</h2>
                        <div className="description" dangerouslySetInnerHTML={{__html: data.description}}/>
                        {data.is_photo_only == 0 ?
                            <div className="video-box">
                                {!ready ?
                                    <div className="photo"
                                         onClick={() => {
                                             setReady(true)
                                             dispatch(textItemLeave())
                                         }
                                         }
                                         onMouseEnter={() => {
                                             dispatch(textItemHover())
                                         }}
                                         onMouseLeave={() => {
                                             dispatch(textItemLeave())
                                         }}
                                         style={{backgroundImage: `url(https://app.lemonsniff.pl/assets/img/uploads/${data.id}.jpg)`}}>
                                        <img src="/lemon-play.svg" alt=""/>
                                    </div> :
                                    <iframe src={`${data.link}?autoplay=1`}
                                            width="640" height="360" frameBorder="0" allow="autoplay; fullscreen"
                                            allowFullScreen></iframe>
                                }
                                <Link ref={prevRef} to={`/${data.prev.category_url}/${data.prev.name_url}`}
                                      className="prev">
                                    <i className="icon-arrow left"></i>
                                    <span>Poprzednia</span>
                                </Link>
                                <Link ref={nextRef} to={`/${data.next.category_url}/${data.next.name_url}`}
                                      className="next">
                                    <span>Następna</span>
                                    <i className="icon-arrow right"></i>
                                </Link>
                            </div>
                            : (<div>
                                {photos}
                            </div>)
                        }
                    </section>
                    <section className="more-realizations">
                        <h2><span>Więcej</span> realizacji</h2>
                        <div className="realizations-row">
                            {realizations}
                        </div>
                    </section>
                </>}
        </div>
    )
}

export default Realization;
