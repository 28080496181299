import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {hideCursor, textItemHover, textItemLeave} from "../../../../actions";
import {useWindowSize} from "../../../hooks/windowSize";

const InitialVideo = (props) => {

    const shortVideoRef = useRef(null)
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false)
    const [ready, setReady] = useState(false)
    const [buttonsMargin, setButtonsMargin] = useState(80)
    const size = useWindowSize()

    useEffect(() => {
        if (visible === true) {
            document.addEventListener('keydown', onEscapeKey);
            return () => {
                document.removeEventListener('keydown', onEscapeKey);
            };
        }
    }, [visible])

    const openVideo = () => {
        setVisible(true)
        setReady(true)
        shortVideoRef.current.pause()
        dispatch(hideCursor())
    }
    const closeVideo = () => {
        setVisible(false)
        setReady(false)
        shortVideoRef.current.play()
        dispatch(hideCursor())
        dispatch(textItemLeave())
    }


    const onEscapeKey = (event) => {
        if (event.key === 'Escape') {
            closeVideo();
        }
    }

    return (
        <section
            onMouseEnter={() => {
                dispatch(textItemHover())
            }}
            onMouseLeave={() => {
                dispatch(textItemLeave())
            }}
            className="video-section photo-hover-element">
            <div className="short-video-wrapper">
                <img src="/lemon-play.svg" alt=""/>
                <video ref={shortVideoRef} playsInline autoPlay muted onClick={openVideo} loop>
                    <source src={`https://app.lemonsniff.pl/assets/img/uploads/lemon-main-short.mp4?v=${props.videoVersion}`} type="video/mp4"/>
                </video>
            </div>
            {props.isLoadingAbout === false && ready ?
                <React.Fragment>
                    <div className={`video-wrapper ${visible ? 'active' : ''}`}>
                        <iframe src={`${props.vimeoLink}?autoplay=1`}
                                width="640" height="360" frameBorder="0" allow="autoplay; fullscreen"
                                allowFullScreen></iframe>
                        <div className="buttons-wrapper container relative">
                            <img className="logo" src="./lemon-sniff-media-logo.svg" alt=""/>
                            <button className="close-btn">
                                <span>zamknij</span>
                                <i className="icon-cross" onClick={closeVideo}></i>
                            </button>
                        </div>
                    </div>
                    <div
                        onClick={closeVideo}
                        className="video-backdrop"
                        onKeyDown={onEscapeKey}
                        tabIndex="0"
                    >
                    </div>
                </React.Fragment>
                : null}
        </section>
    )
}


export default InitialVideo;
