import React, {useEffect, useRef} from "react";
import {Link} from 'react-router-dom'
import ContactButton from "./contact/ContactButton";
import {useSelector} from "react-redux";
import ContactData from "./ContactData";
import {useWindowSize} from "../hooks/windowSize";

const Footer = ({pageWrapperRef, mainPageWrapperRef}) => {

    const {aboutData} = useSelector(state => state.about)
    const sliderRef = useRef(null);
    const size = useWindowSize()


    useEffect(() => {
        if (size > 991) {
            mainPageWrapperRef.current.addEventListener('scroll', scrollingFooterTransform);
        }
    })


    const scrollingFooterTransform = (event) => {
        const scroll = event.srcElement.scrollTop
        const windowHeight = window.innerHeight;
        const untilTransform = pageWrapperRef.current.clientHeight - windowHeight;
        const footerOffset = sliderRef.current.offsetTop;
        const footerHeight = sliderRef.current.clientHeight;
        const startTransform = footerOffset - footerHeight * 2;
        const transformValue = (untilTransform - scroll) / (untilTransform - startTransform) * 25
        const transformStyle = `translate(0, -${transformValue}%)`
        sliderRef.current.style.transform = `translate(0, -25%)`;
        if (scroll >= startTransform && transformValue > 0) {
            sliderRef.current.style.transform = transformStyle;
        } else if (transformValue <= 0) {
            sliderRef.current.style.transform = `translate(0, 0)`;
        }
    }
    const SmoothVerticalScrolling = (e, time, where) => {
        if (e) {
            const eTop = e.getBoundingClientRect().top;
            const eAmt = eTop / 100;
            let curTime = 0;
            while (curTime <= time) {
                window.setTimeout(SVS_B, curTime, eAmt, where);
                curTime += time / 100;
            }
        }
    }

    const SVS_B = (eAmt, where) => {
        if (where === "center" || where === "")
            mainPageWrapperRef.current.scrollBy(0, eAmt / 2);
        if (where === "top")
            mainPageWrapperRef.current.scrollBy(0, eAmt);
    }

    return (
        <footer ref={sliderRef}>
            <div className="container">
                <div className="main-content">
                    <div className="left-side">
                        <div className="lemon-sign"><img src="/lemon-sniff-media-logo.svg" alt="lemon-logo"/></div>
                        <h1>Skontaktuj się <br/>z nami</h1>
                        <ContactButton
                            buttonName="Kontakt"
                        />
                    </div>
                    <div className="menu">
                        <div className="title">Menu</div>
                        <ul>
                            <li>
                                <Link className="translating-button" to="/o-nas">
                                    <em>
                                        <span data-text="O nas">O nas</span>
                                    </em>
                                </Link>
                            </li>
                            <li>
                                <Link className="translating-button" to="/brief">
                                    <em>
                                        <span data-text="Brief">Brief</span>
                                    </em>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <ContactData/>
                </div>
                <div className="bottom-content">
                    <div className="social-box">
                        {aboutData.youtubeLink &&
                        <a rel="noopener noreferrer" target="_blank" href={`${aboutData.youtubeLink}`} className="icon">
                            <i className="icon-youtube"></i>
                        </a>
                        }
                        {aboutData.instagramLink &&
                        <a rel="noopener noreferrer" target="_blank" href={`${aboutData.instagramLink}`}
                           className="icon">
                            <i className="icon-instagram"></i>
                        </a>
                        }
                        {aboutData.facebookLink &&
                        <a rel="noopener noreferrer" target="_blank" href={`${aboutData.facebookLink}`}
                           className="icon">
                            <i className="icon-facebook"></i>
                        </a>
                        }
                        {aboutData.vimeoLinkSocial &&
                        <a rel="noopener noreferrer" target="_blank" href={`${aboutData.vimeoLinkSocial}`}
                           className="icon">
                            <i className="icon-vimeo"></i>
                        </a>
                        }
                    </div>
                    <button type="button" className="go-up"
                            onClick={() => SmoothVerticalScrolling(pageWrapperRef.current, 600, "top")}>
                        <i className="icon-arrow"></i>
                    </button>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
