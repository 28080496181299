const initialState = {
    isLoadingClients: true,
    isErrorClients: false,
    clientsData: [],
}
export default (state= initialState, action) => {
    switch (action.type) {
        case 'GET_CLIENTS_MAIN_PAGE_DATA_REQUESTED':
            return { ...state, isLoadingClients: true };
        case 'GET_CLIENTS_MAIN_PAGE_DATA_DONE':
            return { ...state, isLoadingClients: false, clientsData: action.payload };
        case 'GET_CLIENTS_MAIN_PAGE_DATA_FAILED':
            return { ...state, isLoadingClients: false, isErrorClients: true}
        default:
            return state;
    }
};
