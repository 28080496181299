import React from 'react';
import {Helmet} from 'react-helmet';

const MetaDecorator = ({title}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description"
                  content='Jesteśmy ekipą pasjonującą się produkcją materiałów video,  wykorzystującą nowatorskie oraz niecodzienne pomysły w promocji firm, produktów, usług a także osób prywatnych.'/>
        </Helmet>
    )
}

export default MetaDecorator;
