import React from "react";
import {Link} from "react-router-dom";
import MetaDecorator from "../shared/MetaDecorator";

const NotFound = () => {
    return (
        <section className="not-found-section">
            <MetaDecorator title='Ślepa uliczka - Lemon Sniff'/>
            <h1>Strona nie istnieje :(</h1>
            <Link to="/"><i className="icon-arrow" />Powrót do strony głównej</Link>
        </section>
    )
}

export default NotFound;

