import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Pagination, Autoplay} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import {LazyImage} from "../../../shared/lazyImg/LazyImage";

const ClientsMainPage = ({clientsData}) => {

    SwiperCore.use([Pagination, Autoplay]);

    return (
        <section className="clients-section">
            <h2><span>Nasi</span> klienci</h2>
            <Swiper
                slidesPerView={1}
                pagination={{clickable: true}}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
            >
                {clientsData.map(row => {
                    return (
                        <SwiperSlide>
                            <div className="clients-row" key={row}>
                                <div className="clients">
                                    {
                                        row.map(client => {
                                            return (
                                                <div key={client.id}>
                                                    <a className="client" href={client.link}>
                                                        <LazyImage alt={client.name}
                                                                   src={`https://app.lemonsniff.pl/assets/img/uploads/work/${client.id}.jpg`}/>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </section>
    )
}

export default ClientsMainPage;
