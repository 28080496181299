import myAxios from '../apis/myAxios'

const getMainRealizationsDataRequested = () => {
    return {
        type: 'GET_DATA_REQUESTED'
    };
}


const getMainRealizationsDataDone = data => {
    return {
        type: 'GET_DATA_DONE',
        payload: data
    };
}


const getMainRealizationsDataFailed = () => {
    return {
        type: 'GET_DATA_FAILED'
    };
}

export const getMainRealizations = () => async dispatch => {

    dispatch(getMainRealizationsDataRequested());

    await myAxios.get('/realizations/main_page/1')
        .then(response => {
            dispatch(getMainRealizationsDataDone(response.data));
        })
        .catch(error => {
            dispatch(getMainRealizationsDataFailed(error));
        })
}

//modal
export const openModal = () => {
    return {
        type: 'OPEN_MODAL',
    }
};

export const closingModal = () => {
    return {
        type: 'CLOSING_MODAL',
    }
};

export const closeModal = () => {
    return {
        type: 'CLOSE_MODAL',
    }
};

// Single realization

const getSingleDataRequested = () => {
    return {
        type: 'GET_SINGLE_DATA_REQUESTED'
    };
}

const getSingleDataDone = data => {
    return {
        type: 'GET_SINGLE_DATA_DONE',
        payload: data
    };
}

export const getSingleRealization = (name_url) => async dispatch => {
    dispatch(getSingleDataRequested());

    await myAxios.get(`/realizations/single/${name_url}`)
        .then(response => {
            dispatch(getSingleDataDone(response.data));
        })
        .catch(error => {
            dispatch(getSingleDataFailed(error.response));
        })
}

const getSingleDataFailed = (error) => {
    return {
        type: 'GET_SINGLE_DATA_FAILED',
        payload: error
    };
}

// About

export const getAboutDataRequested = () => {
    return {
        type: 'GET_ABOUT_DATA_REQUESTED'
    };
}


const getAboutDataDone = data => {
    return {
        type: 'GET_ABOUT_DATA_DONE',
        payload: data
    };
}


export const getAboutData = () => async dispatch => {

    dispatch(getAboutDataRequested());

    await myAxios.get('/about')
        .then(response => {
            dispatch(getAboutDataDone(response.data));
        })
        .catch(error => {
            dispatch(getAboutFailed(error));
        })
}


const getAboutFailed = () => {
    return {
        type: 'GET_ABOUT_DATA_ERROR'
    };
}

//Categories

const getCategoriesDataRequested = () => {
    return {
        type: 'GET_CATEGORIES_DATA_REQUESTED'
    }
}

const getCategoriesDataDone = data => {
    return {
        type: 'GET_CATEGORIES_DATA_DONE',
        payload: data
    }
}

const getCategoriesDataError = () => {
    return {
        type: 'GET_CATEGORIES_DATA_FAILED',
    }
}


export const getCategories = () => async dispatch => {
    dispatch(getCategoriesDataRequested());

    await myAxios.get('/categories')
        .then(response => {
            dispatch(getCategoriesDataDone(response.data));
        })
        .catch(error => {
            dispatch(getCategoriesDataError(error));
        })

}

//Clients

const getClientsDataRequested = () => {
    return {
        type: 'GET_CLIENTS_DATA_REQUESTED'
    }
}

const getClientsDataDone = data => {
    return {
        type: 'GET_CLIENTS_DATA_DONE',
        payload: data
    }
}

const getClientsDataError = () => {
    return {
        type: 'GET_CLIENTS_DATA_FAILED',
    }
}


export const getClients = () => async dispatch => {
    dispatch(getClientsDataRequested());

    await myAxios.get('/clients')
        .then(response => {
            dispatch(getClientsDataDone(response.data));
        })
        .catch(error => {
            dispatch(getClientsDataError(error));
        })

}


// Form Contact

const contactFormRequested = () => {
    return {
        type: 'CONTACT_FORM_REQUESTED'
    }
}

const contactFormDone = () => {
    return {
        type: 'CONTACT_FORM_DONE',
    }
}

export const contactFormSendAgain = () => {
    return {
        type: 'CONTACT_FORM_SEND_AGAIN',
    }
}

const contactFormError = () => {
    return {
        type: 'CONTACT_FORM_FAILED',
    }
}

export const resetState = () => {
    return {
        type: 'CONTACT_RESET_STATE',
    }
}


export const postContactForm = (data) => async dispatch => {
    dispatch(contactFormRequested());
    const getFormData = (object) => {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }
    await myAxios.post('/contact', getFormData(data))
        .then(response => {
            setTimeout(() => {
                dispatch(contactFormDone(response));
            }, 1250)
        })
        .catch(error => {
            setTimeout(() => {
                dispatch(contactFormError(error));
            }, 1250)
        })

}

// Video Hover Number

export const videoHoverNumber = id => {
    return {
        type: 'VIDEO_HOVER_NUMBER',
        payload: id
    }
}

export const defaultItemHover = () => {
    return {
        type: 'DEFAULT_ITEM_HOVER',
    }
}
export const defaultItemLeave = () => {
    return {
        type: 'DEFAULT_ITEM_LEAVE',
    }
}

export const textItemHover = () => {
    return {
        type: 'TEXT_ITEM_HOVER',
    }
}

export const textItemLeave = () => {
    return {
        type: 'TEXT_ITEM_LEAVE',
    }
}

export const hideCursor = () => {
    return {
        type: 'HIDE_CURSOR',
    }
}

export const defaultCursor = () => {
    return {
        type: 'DEFAULT_CURSOR',
    }
}

//Clients Main Page

const getClientsMainPageDataRequested = () => {
    return {
        type: 'GET_CLIENTS_MAIN_PAGE_DATA_REQUESTED'
    }
}

const getClientsMainPageDataDone = data => {
    return {
        type: 'GET_CLIENTS_MAIN_PAGE_DATA_DONE',
        payload: data
    }
}

const getClientsMainPageDataError = () => {
    return {
        type: 'GET_CLIENTS_MAIN_PAGE_DATA_FAILED',
    }
}


export const getClientsMainPage = () => async dispatch => {
    dispatch(getClientsMainPageDataRequested());

    await myAxios.get('/clients/main_page/1')
        .then(response => {
            dispatch(getClientsMainPageDataDone(response.data));
        })
        .catch(error => {
            dispatch(getClientsMainPageDataError(error));
        })

}
