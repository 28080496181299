import { combineReducers } from "redux";
import realizationsReducer from "./realizationsReducer";
import realizationReducer from "./realizationReducer";
import modalReducer from "./modalReducer";
import categoriesReducer from "./categoriesReducer";
import aboutReducer from "./aboutReducer";
import clientsReducer from "./clientsReducer";
import contactFormReducer from "./contactFormReducer";
import cursorHoverReducer from "./cursorHoverReducer";
import clientsMainPageReducer from "./clientsMainPageReducer";

export default combineReducers({
    realizations: realizationsReducer,
    categories: categoriesReducer,
    realization: realizationReducer,
    modalShow: modalReducer,
    about: aboutReducer,
    clients: clientsReducer,
    contactForm: contactFormReducer,
    cursorHover: cursorHoverReducer,
    clientsMainPage: clientsMainPageReducer
});
