import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import Realizations from "../realizations/Realizations";
import InitialVideo from "./initialVideo/InitialVideo";
import {Link, useLocation} from "react-router-dom";
import Categories from "../categories/Categories";
import {useDispatch, useSelector} from "react-redux";
import {defaultItemHover, defaultItemLeave, getAboutData, getCategories, getClientsMainPage} from "../../../actions";
import ClientsMainPage from "./clients/Clients";
import Reveal from "react-reveal/Reveal";
import Ceo from "../../shared/ceo/Ceo";
import MetaDecorator from "../../shared/MetaDecorator";

const Main = ({portfolioSectionRef}) => {

    const dispatch = useDispatch();
    const {isLoading, isError, categoriesData} = useSelector(state => state.categories)
    const {isLoadingAbout, isErrorAbout, aboutData} = useSelector(state => state.about)
    const {isLoadingRealization} = useSelector(state => state.realizations)
    const {isLoadingClients, isErrorClients, clientsData} = useSelector(state => state.clientsMainPage)
    const fullHeightRef = useRef();

    useEffect(() => {
        if (categoriesData.length === 0) {
            dispatch(getCategories());
        }
        if (clientsData.length === 0) {
            dispatch(getClientsMainPage());
        }
    }, [])


    return (
        <div className="full-height-wrapper" ref={fullHeightRef} style={{position: "static!important"}}>
            <MetaDecorator title='Lemon Sniff Media - Produkcja video'/>
            <div className="container">
                <section className="title-section">
                    <Reveal effect="fadeInUp" effectOut="fadeOutLeft">
                        <h1
                            onMouseEnter={() => {
                                dispatch(defaultItemHover())
                            }}
                            onMouseLeave={() => {
                                dispatch(defaultItemLeave())
                            }}
                            className="hover-element">
                            <div dangerouslySetInnerHTML={{__html: aboutData.main_description}}/>
                        </h1>
                    </Reveal>
                </section>
            </div>
            <InitialVideo
                vimeoLink={aboutData.vimeoLink}
                videoVersion={aboutData.videoVersion}
                isLoadingAbout={isLoadingAbout}
                isErrorAbout={isErrorAbout}
            />
            <div className="container">
                <section className="about-section">
                    <h3 dangerouslySetInnerHTML={{__html: aboutData.description}}/>
                    <Link to="/o-nas" className="underline-button">Dowiedz się więcej o nas</Link>
                </section>
                {!isLoadingClients &&
                <ClientsMainPage
                    clientsData={clientsData}
                    isLoadingClients={isLoadingClients}
                    isErrorClients={isErrorClients}
                />
                }
                {!isLoading &&
                <Categories
                    sectionRef={portfolioSectionRef}
                    categoriesData={categoriesData}
                    isLoading={isLoading}
                    isError={isError}
                />
                }
                <section className="realizations-section">
                    <h2><span>Wybrane</span> realizacje</h2>
                    <div className="realizations-wrapper">
                        {!isLoadingRealization &&
                        <Realizations/>
                        }
                    </div>
                </section>
                <Ceo
                    seoPage={aboutData.seo_main_page}
                />
            </div>
        </div>
    )
}


export default Main;
