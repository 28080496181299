import React, {useEffect, useRef} from "react";
import Header from "./shared/Header";
import {Route, Switch, useLocation} from "react-router-dom";
import Main from "./pages/mainPage/Main";
import About from "./pages/about/About";
import Realization from "./pages/realizations/Realization";
import Footer from "./shared/Footer";
import Category from "./pages/categories/Category";
import CustomCursor from "./mouseTracking/MouseTruckingDot";
import NotFound from "./pages/404";
import Brief from "./pages/biref/Brief";
import ScrollToTop from "./pageTransition/PageTransition";
import {useWindowSize} from "./hooks/windowSize";
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {defaultCursor} from "../actions";
import {useDispatch} from "react-redux";

const Routes = ({location}) => {

    const pageWrapperRef = useRef(null);
    const mainPageWrapperRef = useRef(null);
    const dispatch = useDispatch();
    const size = useWindowSize();
    const {pathname} = useLocation();
    const portfolioSectionRef = useRef(null)
    const scrollToPortfolio = (smoothScroll) => {
        const portfolioSectionTop = document.querySelector('.categories-section').offsetTop;
        mainPageWrapperRef.current.scrollTo({top: portfolioSectionTop - 101, behavior: "smooth"})
    }

    useEffect(() => {
        dispatch(defaultCursor);
        setTimeout(() => {
            document.body.classList.remove('new-page')
        }, 800)
    }, [location.pathname])

    useEffect(() => {
        if (pathname === '/portfolio') {
            setTimeout(() => {
                scrollToPortfolio()
            },600)
        }
    }, [pathname])

    return (

        <div className="main-page-wrapper" ref={mainPageWrapperRef}>
            <div className="page-wrapper" ref={pageWrapperRef}>
                <div className="section-wrapper">
                    <Header
                        scrollToPortfolio={scrollToPortfolio}
                        pageWrapperRef={pageWrapperRef}
                        mainPageWrapperRef={mainPageWrapperRef}
                    />
                    {size > 767 &&
                    <CustomCursor/>
                    }
                    <ScrollToTop/>
                    <TransitionGroup className="animation-wrapper">
                        <CSSTransition
                            timeout={500}
                            classNames='fade'
                            key={location.key}
                        >
                            <Switch location={location}>
                                <Route path="/" exact component={(props) => <Main {...props} portfolioSectionRef={portfolioSectionRef} />}/>
                                <Route path="/portfolio" exact component={() => <Main portfolioSectionRef={portfolioSectionRef} />}/>
                                <Route path="/o-nas" exact component={About}/>
                                <Route path="/brief" exact component={Brief}/>
                                <Route path="/:categoryName" exact component={Category}/>
                                <Route path="/:categoryName/:name" exact component={Realization}/>
                                <Route component={NotFound}/>
                            </Switch>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                <Footer
                    pageWrapperRef={pageWrapperRef}
                    mainPageWrapperRef={mainPageWrapperRef}
                />
            </div>
        </div>
    )
}


export default Routes;
